<template>
  <v-main class="container-prosker top-container">
    <div
      class="wide-banner border-radius-medium mt-8 mb-16"
      :class="['mobile-blend', localeClass]"
      :style="{
        margin: `0`,
        backgroundColor: `#6b71f2`,
        backgroundImage: `url(${require('@/assets/images/page-img/Vector9.png')})`,
      }"
    >
      <v-row class="pl-lg-3 pr-lg-3 d-flex justify-center">
      <v-col
        class="prosker-text-section text-center d-flex flex-column mt-8 mb-8 p-0"
        col="12"
        md="6"
      >
        <span class="m-order-1 font-weight-bold text-h6 white--text text-lg-left" v-html="$t('freeSoftware')"></span>
        <h1
          class="m-order-0 text-h5 text-md-h2 mb-4 white--text font-weight-bold line-height mt-9 text-lg-left"
        >{{ $t('mostCompletePlatform') }}</h1>
        <p class="mb-0 mt-4 m-order-3 font-size-16 white--text text-lg-left">{{ $t('becomeProsker')  }}</p>
        <span class="m-order-4 font-size-16 white--text text-lg-left">{{ $t('offerService')  }}</span>
        <div class="m-order-2 mt-8 gap-2 d-flex flex-column flex-md-row">
          <general-button
            :action="goToSignUp"
            :loading="false"
            :message="$t('try')"
            size="medium"
            cssClasses="flex-grow-xs-1"
            id="home-start-btn-1"
            :style="{ minWidth: '230px', height: '58px' }"
          />
        </div>
      </v-col>
      <v-col
        class="hero-side-image text-center d-md-flex d-none justify-end align-center"
        cols="6"
      >
        <div>
          <img :src="i18nImage('home_main_banner.png')" style="width: 100%; height: 40em;">

        </div>
      </v-col>
      </v-row>
    </div>
    <h3 class="px-0 px-md-14 text-md-h3 text-h5 text-center mb-16 font-weight-bold text-h3 secondary--text text-lg-left" v-html="$t('simplifyDay')"></h3>
    <div class="py-8 wide-banner text_contrast_to_background">
      <HomeProskerSlider />
    </div>
    <div
      :style="{
      backgroundImage: `url(${require('@/assets/images/page-img/Vector7.png')})`,
      backgroundPosition: 'center bottom'
    }"  >
    <v-row class="py-16">
      <v-col cols="12" class="text-center">
        <h3 class="text-md-h3 text-h5 text-center font-weight-bold mb-8 secondary-prosk" v-html="$t('proskCommissions')"></h3>
      </v-col>
      <v-row class="d-flex justify-lg-space-between justify-center mb-6 mx-2">
          <v-card class="d-flex justify-center align-center border-radius-medium mb-6 w-100-xs mx-2">
            <v-card-title class="w-100-xs">
              <div class="p-2 d-flex flex-column justify-start commissions-box">
                <span class="pt-4 text-md-h4 text-h6 title_highlight_color--text font-weight-bold">1</span>
                <h5 class="text-h6 secondary--text font-weight-bold mb-2">{{ $t('registerProsker') }}</h5>
                <p class="font-size-14">{{ $t('onlyWith') }}</p>
              </div>
            </v-card-title>
          </v-card>
          <v-card class="d-flex justify-center align-center border-radius-medium mb-6 mx-2">
            <v-card-title>
              <div class="p-2 d-flex flex-column justify-start commissions-box">
                <span class="pt-4 text-md-h4 text-h6 title_highlight_color--text font-weight-bold">2</span>
                <h5 class="text-h6 secondary--text font-weight-bold mb-2">{{ $t('completeProfile') }}</h5>
                <p class="font-size-14">{{ $t('profileContent') }}</p>
              </div>
            </v-card-title>
          </v-card>
          <v-card class="d-flex justify-center align-center border-radius-medium mb-6 mx-2">
            <v-card-title>
              <div class="p-2 d-flex flex-column justify-start commissions-box">
                <span class="pt-4 text-md-h4 text-h6 title_highlight_color--text font-weight-bold">3</span>
                <h5 class="text-h6 secondary--text font-weight-bold mb-2">{{ $t('publishService') }}</h5>
                <p class="font-size-14">{{ $t('publishContent') }}</p>
              </div>
            </v-card-title>
          </v-card>
          <v-card class="d-flex justify-center align-center border-radius-medium mb-6 mx-2">
            <v-card-title>
              <div class="p-2 d-flex flex-column justify-center commissions-box">
                <span class="pt-4 text-md-h4 text-h6 title_highlight_color--text font-weight-bold">4</span>
                <h5 class="text-h6 secondary--text font-weight-bold mb-2">{{ $t('assignAvailability') }}</h5>
                <p class="font-size-14">{{ $t('selectDays') }}</p>
              </div>
            </v-card-title>
          </v-card>
      </v-row>
      <div class="d-flex flex-wrap justify-content-center mx-auto">
        <general-button :action="goToSignUp" :loading="false" :message="$t('createFreeAccount')" :style="{ minWidth: '230px', height: '58px' }" size="medium" cssClasses="mx-2 align-self-center align-self-md-start mt-5 font-weight-bold" secondary id="home-register-btn-1"/>
        <general-button :action="goToContact" :loading="false" :message="$t('getInTouch')" :style="{ minWidth: '230px', height: '58px', background: '#6B71F2 !important' }" size="medium" cssClasses="mx-2 align-self-center align-self-md-start mt-5 font-weight-bold" secondary id="home-register-btn-1"/>
      </div>
    </v-row>
    </div>
    <v-row class="border-radius-medium py-4 text-center align-center mt-8 pl-3 pr-3 pr-lg-0 pl-lg-0 justify-center"
          :style="{
        margin: `0`,
        backgroundColor: `#F7F9FF`,
        backgroundBlendMode: `hard-light`,
        backgroundImage: `url(${require('@/assets/images/page-img/Frame427.png')})`,
        backgroundPosition: bottom

      }">
        <v-col cols="12" sm="12" md="5">
          <h3 class="text-md-h3 text-h5 secondary--text font-weight-bold text-center text-md-left">{{ $t('virtualOffice') }}</h3>
          <div class="d-flex align-center justify-content-lg-start justify-content-md-center">
            <img class="mr-2" src="@/assets/images/page-img/chat-bubble.png"/>
            <p class="text-left font-size-16 mt-4" v-html="$t('chatClient')"/>
          </div>
          <div class="d-flex align-center justify-content-lg-start justify-content-md-center">
            <img class="mr-2" src="@/assets/images/page-img/ringing-bell.png"/>
            <p class="text-left font-size-16 mt-4" v-html="$t('notificationReminder')"/>
          </div>
          <div class="d-flex align-center justify-content-lg-start justify-content-md-center">
            <img class="mr-2" src="@/assets/images/page-img/application-add.png"/>
            <p class="text-left font-size-16 mt-4" v-html="$t('automateAgenda')"/>
          </div>
          <div class="d-flex align-center justify-content-lg-start justify-content-md-center">
            <img class="mr-2" src="@/assets/images/page-img/rocket.png"/>
            <p class="text-left font-size-16 mt-4" v-html="$t('ownEcomm')"/>
          </div>
          <div class="d-flex align-center justify-content-lg-start justify-content-md-center">
            <img class="mr-2" src="@/assets/images/page-img/dollar-coin-1.png"/>
            <p class="text-left font-size-16 mt-4" v-html="$t('receivedPayments')"/>
          </div>
          <div class="d-flex justify-content-center justify-content-md-center justify-content-lg-start">
            <general-button :action="goToSignUp" :loading="false" :message="$t('registerSeller')" size="medium" cssClasses="ml-0 align-self-center align-self-md-start mt-5 font-weight-bold" secondary id="home-register-btn-1"/>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" >
            <img :src="i18nImage('img485.png')" class="img-fluid">
        </v-col>
    </v-row>
    <video-banner class="mt-16 margin-bottom-36" :video-url="video.url"  :is-youtube="true" />
    <div class="mb-16 d-flex flex-md-row justify-center">
      <general-button
        :action="goToSignUp"
        :loading="false"
        :message="$t('registerSeller')"
        size="medium"
        cssClasses="flex-grow-xs-1 font-weight-bold"
        id="home-start-btn-1"
      />
    </div>
    <v-row class="pb-16 pt-0">
      <ProskerFeatured />
    </v-row>
    <v-row class="align-items-center">
      <v-col cols="12 pt-16 px-lg-16 px-4 border-radius-medium mb-10"
      :style="{
        backgroundImage: `url(${require('@/assets/images/page-img/Vector_3.png')})`,
        backgroundPosition: 'right bottom',
        backgroundColor: `#6b71f2`
      }">
        <h3 class="col-lg-8 pb-10 text-h5 text-md-h4 white--text text-center text-md-left">
          {{ $t('whatsappContact') }}
        </h3>
        <div class="d-flex justify-content-center justify-content-lg-start pb-16">
          <general-button :action="goToWhatsApp" :loading="false" :message="$t('contactAdvisor')" size="medium" :style="{ minWidth: '230px', height: '58px' }" cssClasses="mt-4 font-weight-bold" />
        </div>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import { USER_TYPE_CLIENT, USER_TYPE_PROSKER } from '@/misc/constants';
import { mapActions } from 'vuex';
import userDataMixin from '@/mixins/userDataMixin';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import VideoBanner from '@/components/socialvue/banners/VideoBanner';
import i18ImagesMixin from '@/mixins/i18ImagesMixin';
import ProskerFeatured from '@/components/socialvue/carousel/ProskerFeatured';
import HomeProskerSlider from '@/components/socialvue/carousel/HomeProskerSlider.vue';

import 'hooper/dist/hooper.css';

export default {
  name: 'HomeProskers',
  components: {
    GeneralButton,
    VideoBanner,
    ProskerFeatured,
    HomeProskerSlider
  },
  mixins: [userDataMixin, i18ImagesMixin],
  data () {
    return {
      cards: [
        {
          icon: 'calendar-add--add-calendar-date-day-month.svg',
          title: this.$t('proskerTilesOneTitle'),
          text: this.$t('proskerTilesOneText')
        },
        {
          icon: 'dollar-coin-1--accounting-billing-payment-cash-coin-currency-money-finance.svg',
          title: this.$t('proskerTilesThreeTitle'),
          text: this.$t('proskerTilesThreeText')
        },
        {
          icon: 'chat-bubble-oval-notification--messages-message-bubble-chat-oval-notify-ping.svg',
          title: this.$t('proskerTilesTwoTitle'),
          text: this.$t('proskerTilesTwoText')
        },
        {
          icon: 'startup--shop-rocket-launch-startup.svg',
          title: this.$t('proskerTilesFourTitle'),
          text: this.$t('proskerTilesFourText')
        }
      ],
      video: {
        url: process.env.VUE_APP_LOCATION.includes('br') ? 'https://www.youtube.com/embed/0Jl5D0kFptk?si=QXPKLKXujvul98Eu' : 'https://www.youtube.com/embed/jp9rOLDpih0?si=PaKrAZwDgUi8pglD',
        title: this.$t('videoTitle'),
        ctaText: this.$t('wantToSignUp')
      },
      tiles: [
        {
          title: this.$t('proskerTilesOneTitle'),
          text: this.$t('proskerTilesOneText')
        },
        {
          title: this.$t('proskerTilesTwoTitle'),
          text: this.$t('proskerTilesTwoText')
        },
        {
          title: this.$t('proskerTilesThreeTitle'),
          text: this.$t('proskerTilesThreeText')
        },
        {
          title: this.$t('proskerTilesFourTitle'),
          text: this.$t('proskerTilesFourText')
        }
      ],
      hooperSettings: {
                    infiniteScroll: true,
                    centerMode: true,
                    autoPlay: true,
                    playSpeed: 3500,
                    breakpoints: {
                        2400: {
                            itemsToShow: 4
                        },
                        1800: {
                            itemsToShow: 4
                        },
                        1500: {
                            itemsToShow: 3
                        },
                        900: {
                            itemsToShow: 3
                        },
                       500: {
                            itemsToShow: 2
                        },
                        0: {
                            itemsToShow: 1
                        }
                    }
                }
    };
  },
  computed: {
    showTestimonials () {
      return process.env.VUE_APP_HIDE_TESTIMONIALS_CARROUSEL !== 'true';
    },
    localeClass () {
      return `locale-${this.$i18n.locale}`;
    }
  },
  created () {
    this.setImportedConstants();
    if (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_CLIENT) {
      this.$router.push({ name: 'social.clients-page' });
    }
  },
  methods: {
    ...mapActions({
      updateSignUpUserType: 'auth/updateSignUpUserType'
    }),
    setImportedConstants () {
        this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
        this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
    },
    goToSubscriptions () {
      this.$router.push({ name: 'social.subscription-page' });
    },
    goToBuy () {
      this.$router.push({ name: 'social.how-works-clients' });
    },
    goToSignUp () {
      this.updateSignUpUserType(this.$options.USER_TYPE_PROSKER);
      this.$router.push({ name: 'auth1.sign-up1' });
    },
    goToContact () {
      this.$router.push({ name: 'social.contact-page' });
    },
    goToWhatsApp () {
      window.location.href = 'https://api.whatsapp.com/send?phone=59892252996';
    }
  },
  mounted () {
    this.trackUserData('load_content', {
      pageType: 'home'
    });
  }
};
</script>
<style lang="scss" scoped>
.top-container {
  margin-top: 0;
}

.locale-br {
  background-position: center right;

  .actions-sub-text,
  .hero-side-image {
    display: none !important;
  }
}

.second-banner.locale-br {
  padding-top: 56px;
}

.margin-bottom-36 {
  margin-bottom: 36px;
}

.prosker-text-section {
  margin-bottom: 80px;
}

.banner-user-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
}

.wide-banner {
  margin: 0 -56px;
  padding: 0 56px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.vector-bg {
  min-height: 500px;
  position: relative;
  overflow: hidden;
  background-position: right -350px top;
}

@media screen and (max-width: 960px) {
  .locale-br {
    background: none;
  }

  .vector-bg {
    background-size: 100%;
    background-position: bottom center;
  }
}

@media screen and (max-width: 920px) {
  .banner-user-menu {
    display: none;
  }
  .mobile-blend {
    background-blend-mode: soft-light;
  }
}
:deep(.VueCarousel-dot) {
  width: 27px !important;
  height: 4px !important;
  border-radius: 0 !important;
}
:deep(.VueCarousel-dot.VueCarousel-dot--active) {
  background-color: #6B71F2 !important;
}
@media screen and (max-width: 920px) {
  .m-order-0 {
    order: 0;
  }
  .m-order-1 {
    order: 1;
  }
  .m-order-2 {
    order: 2;
  }
  .m-order-3 {
    order: 3;
  }
  .m-order-4 {
    order: 4;
  }
}
.commissions-box {
  height: 210px;
  width: 230px;
}
@media screen and (max-width: 600px) {
  .commissions-box {
    height: auto;
    width: 100%;
  }
  .w-100-xs {
    width: 100%;
  }
}

</style>
